import {Application, ApplicationLoaderService, ApplicationStore} from '@application/data';
import {Dictionary, EntitiesChange, EntityAdapter, EntityStore, IdType} from '@axiocode/entity';
import {Injectable, OnDestroy, inject} from '@angular/core';
import {NonFunctionalRequirement, NonFunctionalRequirementStore} from '@non-functional-requirement/data';
import {Observable, Subject, combineLatest, concatMap, distinctUntilChanged, map, mergeMap, tap} from 'rxjs';
import {ActorStore} from '@actor/data';
import {DataModelStore} from '@data-model/data';
import {FeatureStore} from '@feature/data';
import {FormStore} from '@form/data';
import {FunctionalRequirementStore} from '@functional-requirement/data';
import {GlossaryTermStore} from '@glossary-term/data';
import {HttpErrorResponse} from '@angular/common/http';
import {PageStore} from '@page/data';
import {SubSink} from 'subsink';
import {TableStore} from '@table/data';
import {UseCaseStore} from '@use-case/data';
import {Versionable} from '@versionable/data';
import {tapResponse} from '@ngrx/component-store';

import {Comment} from '../models/comment.model';
import {CommentProvider} from './comment.provider';
import {Discussion} from '../models/discussion.model';
import {DiscussionProvider} from './discussion.provider';
import {DiscussionState} from '../models/discussion-state.interface';

export function initializeApplicationLoader(service: ApplicationLoaderService, provider: DiscussionProvider, store: DiscussionStore) {
    return () => service.registerLoader((app: Application) => provider.findAll$().pipe(tap(data => store.upsertMany(data))));
}

@Injectable({providedIn: 'root'})
export class DiscussionStore extends EntityStore<Discussion, DiscussionState> implements OnDestroy {
    #created = new Subject<EntitiesChange<Discussion>>();
    get created(): Observable<EntitiesChange<Discussion>> {
        return this.#created.asObservable();
    }

    #updated = new Subject<EntitiesChange<Discussion>>();
    get updated(): Observable<EntitiesChange<Discussion>> {
        return this.#updated.asObservable();
    }

    #deleted = new Subject<EntitiesChange<Discussion>>();
    get deleted(): Observable<EntitiesChange<Discussion>> {
        return this.#deleted.asObservable();
    }

    #error = new Subject<HttpErrorResponse>();
    get error(): Observable<HttpErrorResponse> {
        return this.#error.asObservable();
    }

    // selectors
    readonly selectAllOpenedDiscussions$ = this.select(
        this.selectAll$,
        discussions => discussions.filter(discussion => !discussion.resolved)
    );

    readonly selectAllReferenced$ = this.select(state => state.referenced);

    // Updaters
    readonly setReferenced = this.updater((state, referenced: Dictionary<Versionable>) => ( {
        ...state,
        referenced
    } ));

    // Effects
    readonly addComment = this.effect((comment$: Observable<Partial<Comment>>) => comment$.pipe(
        concatMap(comment => this.commentProvider.create$(comment).pipe(
            tapResponse(
                comment => {
                    // Add the comment to the stored discussion then update the store.
                    const id = this.getEntityAdapter().selectId(comment.discussion as Discussion);
                    const oldDiscussion = this.get().entities[id];
                    if (undefined !== oldDiscussion) {
                        // Reply to an existing discussion
                        const discussion: Discussion = {
                            ...oldDiscussion,
                            resolved: undefined !== comment.discussion?.resolved ? comment.discussion?.resolved : oldDiscussion.resolved,
                            updatedAt: comment.discussion?.updatedAt ?? oldDiscussion.updatedAt,
                            comments: [
                                ...oldDiscussion.comments,
                                comment
                            ]
                        };
                        this.updateOne(discussion);
                        this.onSuccess({type: 'post', entities: [discussion]});
                    } else {
                        // New discussion
                        const discussion: Discussion = {
                            id: comment.discussion?.id || 0,
                            resolved: comment.discussion?.resolved || false,
                            referenced: comment.discussion?.referenced,
                            createdAt: comment.discussion.createdAt ?? new Date(),
                            updatedAt: comment.discussion.updatedAt ?? new Date(),
                            comments: [comment]
                        };
                        this.upsertOne(discussion);
                        this.onSuccess({type: 'post', entities: [discussion]});
                    }
                },
                error => {
                    if (error instanceof HttpErrorResponse) {
                        this.onError(error);
                    }
                }
            )
        ))
    ));

    readonly updateComment = this.effect((comment$: Observable<Partial<Comment>>) => comment$.pipe(
        concatMap(comment => this.commentProvider.update$(comment, 'patch').pipe(
            tapResponse(
                comment => {
                    // Add the comment to the stored discussion then update the store.
                    const id = this.getEntityAdapter().selectId(comment.discussion as Discussion);
                    const oldDiscussion = this.get().entities[id];
                    if (undefined !== oldDiscussion) {
                        let discussion: Discussion = {
                            ...oldDiscussion,
                            resolved: undefined !== comment.discussion?.resolved ? comment.discussion?.resolved : oldDiscussion.resolved,
                            updatedAt: comment.discussion?.updatedAt ?? oldDiscussion.updatedAt,
                            comments: [...oldDiscussion.comments]
                        };

                        const existingComment = oldDiscussion.comments.findIndex(c => c.id === comment.id);
                        if (-1 < existingComment) {
                            discussion.comments[existingComment] = comment;
                        }
                        this.updateOne(discussion);
                        this.onSuccess({type: 'post', entities: [discussion]});
                    }
                },
                error => {
                    if (error instanceof HttpErrorResponse) {
                        this.onError(error);
                    }
                }
            )
        ))
    ));

    readonly deleteComment = this.effect((comment$: Observable<{comment: Comment, discussion: Discussion}>) => comment$.pipe(
        mergeMap(({comment, discussion}) => this.commentProvider.delete$(comment).pipe(
            tapResponse(
                () => {
                    const d: Discussion = {
                        ...discussion,
                        comments: [...discussion.comments.filter(c => c.id !== comment.id)]
                    };
                    this.updateOne(d);
                    this.onSuccess({type: 'post', entities: [discussion]});
                },
                error => {
                    console.error(error);
                    if (error instanceof HttpErrorResponse) {
                        this.onError(error);
                    }
                }
            )
        ))
    ));

    #provider = inject(DiscussionProvider);
    /** @ignore */
    #subs = new SubSink();

    // Selectors
    readonly selectDiscussionsByReferenced$ = (referencedId: IdType) => this.select(
        this.selectAll$,
        discussions => discussions.filter(discussion => discussion.referenced?.id === referencedId)
    );
    readonly selectReferencedById$ = (id: IdType) => this.select(
        this.selectAllReferenced$,
        allReferenced => allReferenced[id]
    );

    // Reducers
    public override onSuccess(change: EntitiesChange<Discussion>): void {
        switch (change.type) {
            case 'post':
                this.#created.next(change);
                break;

            case 'delete':
                this.#deleted.next(change);
                break;

            case 'patch':
                this.#updated.next(change);
                break;

            default: break;
        }
    }

    public override onError(error: HttpErrorResponse): void {
        this.#error.next(error);
    }

    protected getEntityAdapter(): EntityAdapter<Discussion, DiscussionState> {
        return {
            storeName: 'DiscussionStore',
            initialState: {ids: [], entities: {}, referenced: {}},
            selectId: discussion => discussion.id,
            sort: (a, b) => (a.createdAt || new Date()) > (b.createdAt || new Date()) ? -1 : 1,
        };
    }

    constructor(
        provider: DiscussionProvider,
        private commentProvider: CommentProvider,
        private applicationStore: ApplicationStore,
        private actorStore: ActorStore,
        private dataModelStore: DataModelStore,
        private formStore: FormStore,
        private tableStore: TableStore,
        private glossaryTermStore: GlossaryTermStore,
        private featureStore: FeatureStore,
        private functionalRequirementStore: FunctionalRequirementStore,
        private nonFunctionalRequirementStore: NonFunctionalRequirementStore,
        private pageStore: PageStore,
        private useCaseStore: UseCaseStore
    ) {
        super(provider);

        this.#subs.sink = applicationStore.selectSelectedEntity$.pipe(
            // Filter out if the application is the same as the one we already have
            distinctUntilChanged((previous, current) => previous?.id === current?.id),
            // Resets the state as we changed the current application
            tap(() => this.setState(this.adapter.initialState)),
        ).subscribe();
        this.#subs.sink = combineLatest([
            this.dataModelStore.selectAll$,
            this.actorStore.selectAll$,
            this.formStore.selectAll$,
            this.tableStore.selectAll$,
            this.glossaryTermStore.selectAll$,
            this.featureStore.selectAll$,
            this.functionalRequirementStore.selectAll$,
            this.nonFunctionalRequirementStore.selectAll$,
            this.pageStore.selectAll$,
            this.useCaseStore.selectAll$
        ]).pipe(
            map(([
                dataModels,
                actors,
                forms,
                tables,
                glossaryTerms,
                features,
                functionalRequirements,
                nonFunctionalRequirements,
                pages,
                useCases
            ]) => [
                ...dataModels.map((datamodel): Versionable => ({...datamodel, typeClass: 'datamodel'})),
                ...actors.map((actor): Versionable  => ({...actor, typeClass: 'actor'})),
                ...forms.map((form): Versionable => ({...form, typeClass: 'form'})),
                ...tables.map((table): Versionable => ({...table, typeClass: 'table'})),
                ...glossaryTerms.map((glossaryTerm): Versionable => ({...glossaryTerm, typeClass: 'glossaryterm'})),
                ...features.map((feature): Versionable => ({...feature, typeClass: 'feature'})),
                ...functionalRequirements.map((functionalRequirement): Versionable => ({...functionalRequirement, typeClass: 'functionalrequirement'})),
                ...nonFunctionalRequirements.map((nonFunctionalRequirement): NonFunctionalRequirement => ({...nonFunctionalRequirement, typeClass: 'nonfunctionalrequirement'})),
                ...pages.map((page): Versionable => ({...page, typeClass: 'page'})),
                ...useCases.map((useCase): Versionable => ({...useCase, typeClass: 'usecase'}))
            ]),
            tap(versionables => {
                const dictionary: Dictionary<Versionable> = {};
                for (let versionable of versionables) {
                    dictionary[versionable.id] = versionable;
                }
                this.setReferenced(dictionary);
            })
        ).subscribe();
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.#subs.unsubscribe();
    }
}
